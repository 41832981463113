import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_shop.module.scss"

import Printer from "src/images/info-invoice/PLQ-50S_left.jpg"
import Invoice from "src/images/info-invoice/invoice-product.png"

export default function ShoppingPage () {
  const addPrinterSet = () => { // 伝票プリンターセットをカートに入れてカート画面に移動
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    if (printerNum < 100) {
      const addPrinter = printerNum + 1
      localStorage.setItem("PrinterSetQuantity", addPrinter)
    }
    window.location.href = '/online-store/cart/';
  }

  const addDenpyoOnly = () => { // 伝票だけをカートに入れてカート画面に移動
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"))
    if (denpyoNum < 100) {
      const addDepnyo = denpyoNum + 1
      localStorage.setItem("denpyoOnlyQuantity", addDepnyo)
    }
    window.location.href = '/online-store/cart/';
  }

  return (
    <Layout>
      <Seo title="オンラインショッピング" />
      <div className={styles.shopHeader}>
        <h1>ReNK CHANNEL オンラインショッピング</h1>
      </div>
      <div className="pageWrapper">
        <p className={styles.itemListNum}>商品一覧 : ２件</p>

        <p className={styles.caution}>※ お支払い方法は「銀行振込」のみでございます。</p>

        <div className={styles.productWrapper}>
          <div className={styles.productSection1}>
            <img src={ Invoice } alt="インフォ・ザ・伝票" />
          </div>

          <div className={styles.productSection2}>
            {/* <p className={styles.productNum}>商品コード：0032</p> */}
            <p className={styles.productName}>
              インフォ・ザ・伝票
            </p>
            <p className={styles.price}>13,750円<span className={styles.tax}>（税込）</span><span className={styles.shippingFee}>送料込み</span></p>
            <p>伝票印刷アプリケーション</p>
            <ul>
              <li>専用伝票などの記入項目をドットインパクトプリンターで印刷するソフトです</li>
              <li>運送会社の配送伝票、特殊な納品書、産廃マニフェスト伝票などあらゆる伝票類を簡単に作成することができます</li>
              <li>マニュフェスト(産業廃棄物管理票)のレイアウトは登録済みです。ご購入後すぐにマニュフェストを作成できます</li>
            </ul>

            <div className={styles.addCartWrapper}>
              <button className={styles.addCart} onClick={addDenpyoOnly}>
                カゴに入れて進む
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 30 30" width="30px" fill="#000000"><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
              </button>
              <p className={styles.notes2}>※ ご購入にあたっての注意事項ページへ進みます</p>
            </div>
          </div>
        </div>

        <div className={styles.productWrapper}>
          <div className={styles.productSection1}>
            <img src={ Printer } alt="インフォ・ザ・伝票＆PLQ-50S（プリンター）セット" />
          </div>

          <div className={styles.productSection2}>
            {/* <p className={styles.productNum}>商品コード：0031</p> */}
            <p className={styles.productName}>インフォ・ザ・伝票 ＆<br />
              PLQ-50S（プリンター）セット
            </p>
            <p className={styles.price}>141,900<span className={styles.tax}>（税込）</span><span className={styles.shippingFee}>送料込み</span></p>
            <p>「PLQ-50S」と「インフォ・ザ・伝票」のセット</p>
            <ul>
              <li>カンタンセット機能搭載のドットインパクトプリンター</li>
              <li>位置を気にすることなく用紙をセットできる「カンタンセット機能」搭載</li>
              <li>設置場所を取らないA4用紙2枚分の設置面積</li>
              <li>最大7枚の複写能力に自動紙圧調整搭載</li>
              <li>各種専用伝票を見たまま入力、印刷できる「インフォ・ザ・伝票」を同梱</li>
              <li>マニュフェスト(産業廃棄物管理票)のレイアウトは登録済みです。ご購入後すぐにマニュフェストを作成できます</li>
              <li>セットモデルだけの特典としてヤマト宅急便、ゆうパック、佐川急便の送り状レイアウトを同梱しました</li>
            </ul>

            <div className={styles.addCartWrapper}>
              <button className={styles.addCart} onClick={addPrinterSet}>
                カゴに入れて進む
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 30 30" width="30px" fill="#000000"><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
              </button>
              <p className={styles.notes2}>※ ご購入にあたっての注意事項ページへ進みます</p>
            </div>
          </div>
        </div>

        <hr className="hrGrey" />
        <h2 className="h2Blue">商品に関するお問い合せ</h2>
        <p>お気軽に以下の窓口までお問い合わせください。</p>
        <div className="contactWrapper">
          <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
          <p className="telNum">
            <a href="tel:0258-25-6320">お問い合せ専用電話：
              <span>0258-25-6320</span>
            </a>
          </p>
          <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
          <p className="telNum">
            <a href="mailto:invoice@renk.co.jp">
              E-Mail：invoice@renk.co.jp
            </a>
          </p>
          <p>Fax：0258-25-6309</p>
        </div>
      </div>
    </Layout>
  )
}
